import { DataPoint } from 'src/pages/SignInPage/_BLL/types';
import { db } from '../init';

export const addDataPoints = async (dataPoints: Record<string, DataPoint>) => {
	try {
		await db.dataPoints.bulkAdd(
			Object.entries(dataPoints).map(dataPoint => ({
				name: dataPoint[0],
				value: dataPoint[1],
			})),
		);
	} catch (error) {
		console.log(error);
	}
};
