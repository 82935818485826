import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ! Must be a component because use location can only be used when wrapped by AppRouter.
export function ScrollToTop(): null {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.state]);

	return null;
}
