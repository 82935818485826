import { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { ThemeDecorator } from 'src/app/decorators/ThemeDecorator';
import { store } from 'src/app/redux/store';

interface Props {
	children: ReactNode;
}

export const AppDecorator: FC<Props> = props => {
	const {
		children, //
	} = props;

	return (
		<Provider store={store}>
			<ThemeDecorator>{children}</ThemeDecorator>
		</Provider>
	);
};
