import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/app/router/ui/AppRouter';
import { ButtonRounded } from 'src/shared/ui/_buttons/ButtonRounded';
import s from './NotFoundPage.module.scss';

export const NotFoundPage = () => {
	const navigate = useNavigate();

	return (
		<div
			className={s.container}
			data-test="NotFoundPage"
		>
			<br />

			<h2>That is odd.</h2>
			<br />

			<h2>You seem to have navigated to a nonexistent page.</h2>
			<br />

			<ButtonRounded onClick={() => navigate(routerPath.mainSearch.page)}>Return to Main Page</ButtonRounded>
		</div>
	);
};
