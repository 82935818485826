import { createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunk } from '@reduxjs/toolkit';

type DebounceSettings = {
	maxWait?: number;
	leading?: boolean;
};

export const createDebouncedAsyncThunk = <Returned, ThunkArg = void>(
	typePrefix: string,
	payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
	wait: number,
	options?: DebounceSettings
	// eslint-disable-next-line @typescript-eslint/ban-types
): AsyncThunk<Returned, ThunkArg, {}> => {
	const { maxWait = 0, leading = false } = options ?? {};
	let timer = 0;
	let maxTimer = 0;
	let resolve: ((value: boolean) => void) | undefined;
	const invoke = (): void => {
		window.clearTimeout(maxTimer);
		maxTimer = 0;
		if (resolve) {
			resolve(true);
			resolve = undefined;
		}
	};
	const cancel = (): void => {
		if (resolve) {
			resolve(false);
			resolve = undefined;
		}
	};
	return createAsyncThunk<Returned, ThunkArg>(typePrefix, payloadCreator as never, {
		condition() {
			const immediate = leading && !timer;
			window.clearTimeout(timer);
			timer = window.setTimeout(() => {
				invoke();
				timer = 0;
			}, wait);
			if (immediate) return true;
			cancel();
			if (maxWait && !maxTimer) maxTimer = window.setTimeout(invoke, maxWait);
			return new Promise<boolean>(res => {
				resolve = res;
			});
		},
	});
};
