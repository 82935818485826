import cn from 'classnames';
import React, { DetailedHTMLProps, FC, HTMLAttributes, memo } from 'react';
import LogoSVG from 'src/shared/assets/svg/denominator_logo.svg';
import LogoNoLineSVG from 'src/shared/assets/svg/denominator_logo_no_line.svg';
import s from './Logo.module.scss';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	line?: boolean;
	onClick?: (e: React.SyntheticEvent) => void;
}

export const Logo: FC<Props> = memo(props => {
	const {
		line = true, //
		onClick,
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div
			className={className}
			onClick={onClick}
			{...divProps}
		>
			{line ? <LogoSVG className={cn(s.container)} /> : <LogoNoLineSVG className={cn(s.container)} />}
		</div>
	);
});
