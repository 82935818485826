// Taken from https://www.colorhexa.com/color-names

export const CONTRAST_PALETTE = [
	'#5d8aa8',
	'#6699cc',
	'#e32636',
	'#efdecd',
	'#e52b50',
	'#ffbf00',
	'#ff033e',
	'#9966cc',
	'#a4c639',
	'#b5a642',
	'#cd9575',
	'#915c83',
	'#faebd7',
	'#008000',
	'#8db600',
	'#fbceb1',
	'#00ffff',
	'#7fffd4',
	'#4b5320',
	'#e9d66b',
	'#b2beb5',
	'#87a96b',
	'#ff9966',
	'#a52a2a',
	'#fdee00',
	'#6e7f80',
	'#ff2052',
	'#007fff',
	'#66ff00',
	'#89cff0',
	'#a1caf1',
	'#f4c2c2',
	'#21abcd',
	'#fae7b5',
	'#ffe135',
	'#848482',
	'#98777b',
	'#bcd4e6',
	'#9f8170',
	'#ffa812',
	'#ffe4c4',
	'#3d2b1f',
	'#fe6f5e',
	'#000000',
	'#ffebcd',
	'#318ce7',
	'#ace5ee',
	'#faf0be',
	'#0000ff',
	'#a2a2d0',
];
