import { SearchResultIndustryCountry } from '../../searchResults/types';

const INDUSTRY_DATA_POINTS = {
	industryIndexScore: 'Industry Index Score',
	industryOnly: 'Industry Only Score',
	aCompanyAVGIndustry: 'Advanced Total Score (Industry Average)',
	advancedGenderAVGIndustry: 'Advanced Gender Score (Industry Average)',
	advancedRaceAVGIndustry: 'Advanced Race Score (Industry Average)',
	advancedAgeAVGIndustry: 'Advanced Age Score (Industry Average)',
	advancedSexualityAVGIndustry: 'Advanced Sexuality Score (Industry Average)',
	advancedEducationAVGIndustry: 'Advanced Education Score (Industry Average)',
	advancedHealthAVGIndustry: 'Advanced Health Score (Industry Average)',
	advancedDisabilityAVGIndustry: 'Advanced Disability Score (Industry Average)',
	advancedFamilyAVGIndustry: 'Advanced Family Score (Industry Average)',
	advancedJobAVGIndustry: 'Advanced Job Score (Industry Average)',
	advancedDEIPolicyAVGIndustry: 'Advanced DEI Policy Score (Industry Average)',
	advancedEquityAndInclusionAVGIndustry: '	Advanced Equity And Inclusion Score',
};

const COUNTRY_DATA_POINTS = {
	averageAdvancedTotal: 'Advanced Total Score (Country Average)',
	averageAdvancedForecast: 'Advanced Forecast Score (Country Average)',
	averageAdvancedGenderBoard: 'Board Gender Score (Country Average)',
	averageAdvancedGenderExecutive: 'Executives Gender Score (Country Average)',
	averageAdvancedRaceBoard: 'Board Race/Ethnicity Score (Country Average)',
	averageAdvancedRaceExecutive: 'Executives Race/Ethnicity Score (Country Average)',
	averageAdvancedInclusion: 'Advanced Equity and Inclusion Score (Country Average)',
	averageAdvancedDEIFocus: 'Advanced DEI Policy Score (Country Average)',
	countryScoreTotal: 'Country Index Score',
	countryDiversity: 'Country Diversity Score',
	countryInclusion: 'Country Inclusion Score',
	countryEquity: 'Country Equity Score',
};

const DATA_POINTS: { [key: string]: number | string } = {
	...INDUSTRY_DATA_POINTS,
	...COUNTRY_DATA_POINTS,
};

export const parseResultsForTable = (searchResults: SearchResultIndustryCountry[]) => {
	return searchResults.map(searchResult => {
		const parsedProperties: Record<string, string> = {};

		Object.entries(searchResult)
			.filter(([dataPoint]) => dataPoint !== 'id' && dataPoint !== 'name')
			.forEach(([dataPoint, value]) => {
				const key = DATA_POINTS[dataPoint] ?? dataPoint;
				Object.assign(parsedProperties, { [key]: value });
			});

		return {
			id: searchResult.id,
			name: searchResult.name,
			...parsedProperties,
		};
	});
};
