import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { CONTRAST_PALETTE } from 'src/shared/consts/contrastColors';
import { IPagination } from 'src/shared/types/pagination';
import { PortfolioCreateEditREQ } from './reqTypes';
import { UploadPortfolioRES } from './resTypes';
import {
	AbsoluteBenchmarkScore,
	Distribution,
	ExposureDistributionItem,
	ExposureItem,
	Portfolio,
	PortfolioInfo,
	InfoItem,
	QuartileDistributionItem,
} from 'src/shared/types/portfolio_funds/portfolio_funds';
import { IOverview } from 'src/entities/portfolio_funds/Overview/types';
import { EditMeta } from 'src/shared/types/portfolio_funds/portfolio_funds';

const NAME = UrlAPI.portfolioAnalyzer;

export const getEditMetadata = createAppAsyncThunk(`${NAME}/getEditMetadata`, async (_: void, thunkAPI) => {
	return await apiRequest.getRequest<EditMeta>({
		url: `${NAME}/editMetadata`,
		thunkAPI,
	});
});

export const getOverview = createAppAsyncThunk(`${NAME}/getOverview`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<IOverview>({
		url: `${NAME}/overview`,
		params,
		thunkAPI,
	});
});

export const getPortfolio = createAppAsyncThunk(`${NAME}/getPortfolio`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<Portfolio>({
		url: `${NAME}/portfolio`,
		params,
		thunkAPI,
	});
});

export const savePortfolio = createAppAsyncThunk(`${NAME}/postPortfolio`, async (arg: PortfolioCreateEditREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<Portfolio>({
		url: `${NAME}/portfolio`,
		payload,
		thunkAPI,
	});
});

export const deletePortfolio = createAppAsyncThunk(`${NAME}/deletePortfolio`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	await apiRequest.delRequest({
		url: `${NAME}/portfolio`,
		params,
		thunkAPI,
	});
});

export const getPortfoliosInfo = createAppAsyncThunk(`${NAME}/postPortfolios`, async (arg: { payload: IPagination }, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<PortfolioInfo[]>({
		url: `${NAME}/portfolios`,
		payload,
		thunkAPI,
	});
});

export const uploadPortfolio = createAppAsyncThunk(`${NAME}/uploadPortfolio`, async (arg: { payload: FormData }, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postFormDataRequest<UploadPortfolioRES>({
		url: `${NAME}/portfolio/excel`,
		payload,
		thunkAPI,
	});
});

export const getQuartileDistribution = createAppAsyncThunk(`${NAME}/getQuartileDistribution`, async (arg: { params: { id: number; dimensionId: string } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<QuartileDistributionItem>({
		url: `${NAME}/quartileDistribution`,
		params,
		thunkAPI,
	});
});

export const getRegionalDistribution = createAppAsyncThunk(`${NAME}/getRegionalDistribution`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<Distribution[]>({
		url: `${NAME}/regionalDistribution`,
		params,
		thunkAPI,
	});
});

export const getSectoralDistribution = createAppAsyncThunk(`${NAME}/getSectoralDistribution`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<Distribution[]>({
		url: `${NAME}/sectoralDistribution`,
		params,
		thunkAPI,
	});
});

export const getAbsoluteVsBenchmarkScores = createAppAsyncThunk(`${NAME}/absoluteVsBenchmarkScores`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<AbsoluteBenchmarkScore[]>({
		url: `${NAME}/absoluteVsBenchmarkScores`,
		params,
		thunkAPI,
	});
});

export const getExposure = createAppAsyncThunk(`${NAME}/getExposure`, async (arg: { params: { id: number; belowBenchmark: boolean } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<ExposureItem[]>({
		url: `${NAME}/exposure`,
		params,
		thunkAPI,
	});
});

export const getExposureDistribution = createAppAsyncThunk(
	`${NAME}/getExposureDistribution`,
	async (arg: { params: { id: number; belowBenchmark: boolean; dataPointId: number; dimensionId: string } }, thunkAPI) => {
		const { params } = arg;

		return await apiRequest.getRequest<ExposureDistributionItem[]>({
			url: `${NAME}/exposureDistribution`,
			params,
			thunkAPI,
		});
	},
);

interface State {
	editMeta: EditMeta | null;
	overview: IOverview | null;
	below: boolean;
	portfolios: PortfolioInfo[];
	portfolio: Portfolio | null;
	dimensionsList: InfoItem[];
	quartileDistribution: QuartileDistributionItem | null;
	regionalDistribution: {
		data: Distribution[];
		status: RequestStatus;
	};
	sectoralDistribution: {
		data: Distribution[];
		status: RequestStatus;
	};
	absoluteBenchmarkScores: {
		data: AbsoluteBenchmarkScore[];
		status: RequestStatus;
	};
	exposure: {
		data: ExposureItem[];
		status: RequestStatus;
	};
	exposureDistribution: {
		[key: string]: {
			data: ExposureDistributionItem[];
			status: RequestStatus;
		};
	};
	status: RequestStatus;
}

export const initialState: State = {
	editMeta: null,
	overview: null,
	below: true,
	portfolios: [],
	portfolio: null,
	dimensionsList: [],
	quartileDistribution: null,
	regionalDistribution: {
		data: [],
		status: RequestStatus.still,
	},
	sectoralDistribution: {
		data: [],
		status: RequestStatus.still,
	},
	absoluteBenchmarkScores: {
		data: [],
		status: RequestStatus.still,
	},
	exposure: {
		data: [],
		status: RequestStatus.still,
	},
	exposureDistribution: {},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setBelow: (state, action: PayloadAction<boolean>) => {
			state.below = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getEditMetadata.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getEditMetadata.fulfilled, (state, action) => {
			const benchmarks = action.payload.benchmarks.map((benchmark, index) => ({
				...benchmark,
				color: CONTRAST_PALETTE[index],
			}));

			// Placing Median between 25 and 75.
			for (let i = 0; i < benchmarks.length; i++) {
				if (benchmarks[i].name.includes('25')) {
					const medianBenchmark = benchmarks[i - 1];

					benchmarks[i - 1] = benchmarks[i];
					benchmarks[i] = medianBenchmark;
				}
			}

			state.editMeta = {
				...action.payload,
				benchmarks,
				indexes: action.payload.indexes.map((indexScore, index) => ({
					...indexScore,
					color: CONTRAST_PALETTE[action.payload.benchmarks.length + index],
				})),
			};
			state.dimensionsList = action.payload.dimensionGroups.map(dimensionGroup => dimensionGroup.dimensions.map(dimension => dimension)).flat();
			state.status = RequestStatus.still;
		});
		builder.addCase(getEditMetadata.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getOverview.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getOverview.fulfilled, (state, action) => {
			state.overview = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getOverview.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getPortfolio.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getPortfolio.fulfilled, (state, action) => {
			state.portfolio = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getPortfolio.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(savePortfolio.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(savePortfolio.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(savePortfolio.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(deletePortfolio.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(deletePortfolio.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(deletePortfolio.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getPortfoliosInfo.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getPortfoliosInfo.fulfilled, (state, action) => {
			state.portfolios = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getPortfoliosInfo.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(uploadPortfolio.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(uploadPortfolio.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(uploadPortfolio.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getQuartileDistribution.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getQuartileDistribution.fulfilled, (state, action) => {
			state.quartileDistribution = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getQuartileDistribution.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getRegionalDistribution.pending, state => {
			state.regionalDistribution.status = RequestStatus.loading;
		});
		builder.addCase(getRegionalDistribution.fulfilled, (state, action) => {
			state.regionalDistribution.data = action.payload;
			state.regionalDistribution.status = RequestStatus.still;
		});
		builder.addCase(getRegionalDistribution.rejected, state => {
			state.regionalDistribution.status = RequestStatus.failed;
		});

		builder.addCase(getSectoralDistribution.pending, state => {
			state.sectoralDistribution.status = RequestStatus.loading;
		});
		builder.addCase(getSectoralDistribution.fulfilled, (state, action) => {
			state.sectoralDistribution.data = action.payload;
			state.sectoralDistribution.status = RequestStatus.still;
		});
		builder.addCase(getSectoralDistribution.rejected, state => {
			state.sectoralDistribution.status = RequestStatus.failed;
		});

		builder.addCase(getAbsoluteVsBenchmarkScores.pending, state => {
			state.absoluteBenchmarkScores.status = RequestStatus.loading;
		});
		builder.addCase(getAbsoluteVsBenchmarkScores.fulfilled, (state, action) => {
			state.absoluteBenchmarkScores.data = action.payload;
			state.absoluteBenchmarkScores.status = RequestStatus.still;
		});
		builder.addCase(getAbsoluteVsBenchmarkScores.rejected, state => {
			state.absoluteBenchmarkScores.status = RequestStatus.failed;
		});

		builder.addCase(getExposure.pending, state => {
			state.exposure.status = RequestStatus.loading;
		});
		builder.addCase(getExposure.fulfilled, (state, action) => {
			state.exposure.data = action.payload;
			state.exposure.status = RequestStatus.still;
		});
		builder.addCase(getExposure.rejected, state => {
			state.exposure.status = RequestStatus.failed;
		});

		builder.addCase(getExposureDistribution.pending, (state, action) => {
			const { dimensionId, dataPointId } = action.meta.arg.params;

			state.exposureDistribution[`${dimensionId}_${dataPointId}`] = {
				data: [],
				status: RequestStatus.loading,
			};
		});
		builder.addCase(getExposureDistribution.fulfilled, (state, action) => {
			const { dimensionId, dataPointId } = action.meta.arg.params;

			state.exposureDistribution[`${dimensionId}_${dataPointId}`] = {
				data: action.payload,
				status: RequestStatus.failed,
			};
		});
		builder.addCase(getExposureDistribution.rejected, (state, action) => {
			const { dimensionId, dataPointId } = action.meta.arg.params;

			state.exposureDistribution[`${dimensionId}_${dataPointId}`] = {
				data: [],
				status: RequestStatus.failed,
			};
		});
	},
});

export const actionsPortfolioAnalyzer = {
	...slice.actions,
	getEditMetadata,
	getOverview,
	getPortfolio,
	savePortfolio,
	deletePortfolio,
	getPortfoliosInfo,
	uploadPortfolio,
	getQuartileDistribution,
	getRegionalDistribution,
	getSectoralDistribution,
	getAbsoluteVsBenchmarkScores,
	getExposure,
	getExposureDistribution,
};
