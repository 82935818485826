import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import {
	AiResponse,
	AiTemplate,
	CompanyListItem,
	GetAiReportREQ,
	GetAiReportsListREQ,
	GetCompanyAiREQ,
	GetCompanyAiRES,
	PostAiReportsAssignREQ,
	PostAiReportsAssignRES,
	PostAiReportsREQ,
	PostAiReportsRES,
} from './types';

const NAME = UrlAPI.ai;

// GET company/ai - Take report from default template cash or generate a new one.
const getCompanyReport = createAppAsyncThunk(`${NAME}/getChatResult`, async (arg: GetAiReportREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<AiResponse>({
		url: `company/${NAME}`, // !Exception
		params,
		thunkAPI,
	});
});

// GET ai/templates
const getTemplates = createAppAsyncThunk(`${NAME}/getTemplates`, async (arg: void, thunkAPI) => {
	return await apiRequest.getRequest<AiTemplate[]>({
		url: `${NAME}/templates`,
		thunkAPI,
	});
});

const createTemplate = createAppAsyncThunk(`${NAME}/createTemplate`, async (arg: { payload: AiTemplate }, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest({
		url: `${NAME}/templates`,
		payload,
		thunkAPI,
	});
});

const removeTemplate = createAppAsyncThunk(`${NAME}/removeTemplate`, async (arg: { params: { template_name: string } }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.delRequest({
		url: `${NAME}/templates`,
		params,
		thunkAPI,
	});
});

// GET ai/reports - Accepts company_id and template_name and REFRESHES report for selected company.
const getCompanyReportByTemplate = createAppAsyncThunk(`${NAME}/getCompanyReport`, async (arg: GetCompanyAiREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetCompanyAiRES>({
		url: `${NAME}/reports`,
		params,
		thunkAPI,
	});
});

const getListOfCompaniesHavingReport = createAppAsyncThunk(`${NAME}/getListOfCompaniesHavingReport`, async (arg: GetAiReportsListREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<CompanyListItem[]>({
		url: `${NAME}/reports/list`,
		params,
		thunkAPI,
	});
});

/* POST ai/reports - Refreshes the reports by default template for selected company ids.
Also is possible to use a non default template if searchPrefix is provided. */
const generateCompaniesReports = createAppAsyncThunk(`${NAME}/generateCompaniesReports`, async (arg: PostAiReportsREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<PostAiReportsRES>({
		url: `${NAME}/reports`,
		payload,
		thunkAPI,
	});
});

const assignCompaniesReports = createAppAsyncThunk(`${NAME}/assignCompaniesReports`, async (arg: PostAiReportsAssignREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<PostAiReportsAssignRES>({
		url: `${NAME}/reports/assign`,
		payload,
		thunkAPI,
	});
});

interface State {
	templates: AiTemplate[];
	report: string | null;
	oldReport: string | null;
	companiesList: CompanyListItem[];
	reportSaveStatus: string;
	status: RequestStatus;
}

export const initialState: State = {
	templates: [],
	report: null,
	oldReport: null,
	companiesList: [],
	reportSaveStatus: '',
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getCompanyReport.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanyReport.fulfilled, (state, action) => {
			state.report = action.payload.report;
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompanyReport.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getTemplates.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getTemplates.fulfilled, (state, action) => {
			state.templates = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getTemplates.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(createTemplate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createTemplate.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createTemplate.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeTemplate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeTemplate.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(removeTemplate.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getCompanyReportByTemplate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanyReportByTemplate.fulfilled, (state, action) => {
			const { report, oldReport } = action.payload;
			state.report = report ? report : state.report;
			state.oldReport = oldReport;
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompanyReportByTemplate.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getListOfCompaniesHavingReport.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getListOfCompaniesHavingReport.fulfilled, (state, action) => {
			state.companiesList = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getListOfCompaniesHavingReport.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(generateCompaniesReports.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(generateCompaniesReports.fulfilled, (state, action) => {
			const { status } = action.payload;
			state.reportSaveStatus = status;
			state.status = RequestStatus.still;
		});
		builder.addCase(generateCompaniesReports.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(assignCompaniesReports.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(assignCompaniesReports.fulfilled, (state, action) => {
			const { status } = action.payload;
			state.reportSaveStatus = status;
			state.status = RequestStatus.still;
		});
		builder.addCase(assignCompaniesReports.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsChatGptAsync = {
	getCompanyReport,
	getCompanyReportByTemplate,
	getListOfCompaniesHavingReport,
	generateCompaniesReports,
	assignCompaniesReports,
	getTemplates,
	createTemplate,
	removeTemplate,
};
