import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { ConfigTableCompany } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { BodyCompany, SearchResultCompany } from 'src/pages/MainSearchResultsPage/api/searchResults/types';
import { IPagination } from 'src/shared/types/pagination';
import { ResponseStatus } from 'src/pages/MainPage/api/booleanSearch/types';
import { apiRequest } from 'src/shared/api/api';
import { wait } from 'src/shared/lib/async';
import { CompaniesLookupRES, SearchResponseCompany } from 'src/pages/MainPage/api/mainSearch/types';
import { actionsNotifications } from 'src/app/_BLL/notifications/slice';
import { createDebouncedAsyncThunk } from 'src/shared/api/createDebouncedAsyncThunk';

const NAME = UrlAPI.portfolioAnalyzer;

export const getCompanies = createAppAsyncThunk(`${NAME}/getCompanies`, async (lastCompanyRequest: Omit<BodyCompany, 'pagination'>, thunkAPI) => {
	let res;
	let status: ResponseStatus = null;
	let queryId: number | null = null;

	do {
		res = await apiRequest.postRequest<{ result: { companies: SearchResultCompany[]; pagination: IPagination } | null; queryId: number | null; status: ResponseStatus }>({
			url: 'companies',
			payload:
				queryId !== null
					? { queryId }
					: {
							...lastCompanyRequest,
							pagination: {
								pageSize: 5000,
								pageIndex: 0,
							},
							queryId,
						},
			thunkAPI,
		});

		queryId = res?.queryId;
		status = res?.status;

		if (res?.queryId && res.status === 'InProgress') {
			await wait(3000);
		}
	} while (status === 'InProgress');

	return res.result;
});

export const searchCompanies = createDebouncedAsyncThunk(
	`${NAME}/searchCompanies`,
	async (payload: { searchPrefix?: string; pagination?: IPagination }, thunkAPI) => {
		const { signal, dispatch } = thunkAPI;

		if (payload.searchPrefix !== '') {
			const res = await apiRequest.postRequest<CompaniesLookupRES>({
				url: UrlAPI.companiesLookup,
				payload,
				thunkAPI,
				signal,
			});

			if (res.companies.length === 0) {
				dispatch(
					actionsNotifications.addNotification({
						type: 'info',
						message: 'Nothing found',
					}),
				);
			}

			return res;
		} else {
			return {
				companies: [],
				pagination: {
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
					count: 0,
				},
			};
		}
	},
	500,
);

interface State {
	name: string;
	share: boolean;
	companies: ConfigTableCompany[];
	dimensions: number[];
	benchmarks: number[];
	indexes: number[];
	benchmarkType: number;
	companiesSearch: {
		status: RequestStatus;
		companies: SearchResponseCompany[];
	};
	status: RequestStatus;
}

export const initialState: State = {
	name: '',
	share: false,
	companies: [],
	dimensions: [],
	benchmarks: [],
	indexes: [],
	benchmarkType: 0,
	companiesSearch: {
		status: RequestStatus.still,
		companies: [],
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setName: (state, action: PayloadAction<string>) => {
			state.name = action.payload;
		},
		setDimensions: (state, action: PayloadAction<number[]>) => {
			state.dimensions = action.payload;
		},
		setBenchmarks: (state, action: PayloadAction<number[]>) => {
			state.benchmarks = action.payload;
		},
		setIndexes: (state, action: PayloadAction<number[]>) => {
			state.indexes = action.payload;
		},
		setBenchmarkType: (state, action: PayloadAction<number>) => {
			state.benchmarkType = action.payload;
		},
		setConfig: (state, action: PayloadAction<{ name: string; dimensions: number[]; benchmarks: number[]; indexes: number[]; benchmarkType: number }>) => {
			const { name, dimensions, benchmarks, indexes, benchmarkType } = action.payload;

			state.name = name;
			state.dimensions = dimensions;
			state.benchmarks = benchmarks;
			state.indexes = indexes;
			state.benchmarkType = benchmarkType;
		},
		setShare: (state, action: PayloadAction<boolean>) => {
			state.share = action.payload;
		},
		setConfigCompanies: (state, action: PayloadAction<ConfigTableCompany[]>) => {
			state.companies = action.payload;
		},
		clearConfig: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(getCompanies.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanies.fulfilled, (state, action) => {
			if (action.payload) {
				state.companies = action.payload.companies.map(company => ({
					id: company.id,
					name: company.name,
					share: null,
					checked: true,
				}));
			}
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompanies.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(searchCompanies.pending, state => {
			state.companiesSearch.status = RequestStatus.loading;
		});
		builder.addCase(searchCompanies.fulfilled, (state, action) => {
			state.companiesSearch.companies = action.payload.companies;
			state.companiesSearch.status = RequestStatus.still;
		});
		builder.addCase(searchCompanies.rejected, state => {
			state.companiesSearch.status = RequestStatus.failed;
		});
	},
});

export const actionsPortfolioConfig = {
	...slice.actions,
	getCompanies,
	searchCompanies,
};
