interface ObjWithId {
	id: number;
}

// Replace one object in an array by ID.
export const replaceArrayItemByID = <T extends ObjWithId>(arr: Array<T>, item: T) => {
	const index = arr.findIndex(({ id }) => id === item.id);
	if (index === -1) {
		return arr;
	}
	arr[index] = item;
	return arr;
};
