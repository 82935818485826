import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StringInputOutlined } from 'src/shared/ui/_inputs/StringInputOutlined';

interface Props {
	label: string;
	name: string;
	placeholder?: string;
	dataTest?: string;
}

export const StringField: FC<Props> = props => {
	const {
		label, //
		name,
		placeholder,
		dataTest,
	} = props;

	const { control } = useFormContext();

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<StringInputOutlined
					{...field}
					data-test={dataTest}
					size="small"
					variant="outlined"
					label={label}
					placeholder={placeholder}
					error={!!fieldState.error?.message}
					helperText={fieldState.error?.message}
				/>
			)}
		/>
	);
};
