import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { GetBoardAndExecRES, GetGeneralRES, GetPerformanceRES, GetSelectedMetricsRES } from './resTypes';
import { ResponseStatus } from 'src/pages/MainPage/api/booleanSearch/types';
import { SearchResultCompany } from 'src/pages/MainSearchResultsPage/api/searchResults/types';
import { IPagination } from 'src/shared/types/pagination';
import { wait } from 'src/shared/lib/async';
import { SearchResponseCompany } from 'src/pages/MainPage/api/mainSearch/types';

const NAME = 'database_new';

// * Async thunks
export const getGeneral = createAppAsyncThunk(`${NAME}/getGeneral`, async (arg: { params: { id: string } }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<GetGeneralRES>({
		url: `${UrlAPI.company}/general`,
		params,
		thunkAPI,
		signal,
	});
});

export const getPerformance = createAppAsyncThunk(`${NAME}/getPerformance`, async (arg: { params: { id: string } }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<GetPerformanceRES>({
		url: `${UrlAPI.company}/performance`,
		params,
		thunkAPI,
		signal,
	});
});

export const getBoardAndExec = createAppAsyncThunk(`${NAME}/getBoardAndExec`, async (arg: { params: { id: string } }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<GetBoardAndExecRES>({
		url: `${UrlAPI.company}/boardAndExec`,
		params,
		thunkAPI,
		signal,
	});
});

export const getSelectedMetrics = createAppAsyncThunk(`${NAME}/getSelectedMetrics`, async (arg: { id: string; dataPoints: string[] }, thunkAPI) => {
	const { id, dataPoints } = arg;
	const { signal } = thunkAPI;

	let res;
	let status: ResponseStatus = null;
	let queryId: number | null = null;

	do {
		res = await apiRequest.postRequest<GetSelectedMetricsRES>({
			url: 'companies',
			payload:
				queryId !== null
					? { queryId }
					: {
							columnsWithPeriods: dataPoints.map(dataPointId => ({
								associatedDataTakenFromCompany: false,
								datapointId: +dataPointId,
								reportingPeriodId: null,
							})),
							ids: [+id],
						},
			thunkAPI,
			signal,
		});

		queryId = res?.queryId;
		status = res?.status;

		if (res?.queryId && res.status === 'InProgress') {
			await wait(3000);
		}
	} while (status === 'InProgress');

	return res.result;
});

// * Reducer
export interface State {
	general: {
		data: GetGeneralRES | null;
		status: RequestStatus;
	};
	performance: {
		data: GetPerformanceRES;
		status: RequestStatus;
	};
	boardAndExec: {
		data: GetBoardAndExecRES | null;
		status: RequestStatus;
	};
	selectedMetrics: {
		data: GetSelectedMetricsRES['result'] | null;
		status: RequestStatus;
	};
	pdfImages: Record<string, string> | null;
	imagesLoading: number;
}

export const initialState: State = {
	general: {
		data: null,
		status: RequestStatus.still,
	},
	performance: {
		data: [],
		status: RequestStatus.still,
	},
	boardAndExec: {
		data: null,
		status: RequestStatus.still,
	},
	selectedMetrics: {
		data: null,
		status: RequestStatus.still,
	},
	pdfImages: null,
	imagesLoading: 0,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		imageLoadStart: state => {
			state.imagesLoading = state.imagesLoading + 1;
		},
		imageSave: (state, action: { payload: { title: string; image: string } }) => {
			const { title, image } = action.payload;

			state.pdfImages = {
				...state.pdfImages,
				[title]: image,
			};
			state.imagesLoading = state.imagesLoading - 1;
		},
	},
	extraReducers: builder => {
		builder.addCase(getGeneral.pending, state => {
			state.general.status = RequestStatus.loading;
		});
		builder.addCase(getGeneral.fulfilled, (state, action) => {
			state.general.status = RequestStatus.still;
			state.general.data = action.payload;
		});
		builder.addCase(getGeneral.rejected, state => {
			state.general.status = RequestStatus.failed;
		});

		builder.addCase(getPerformance.pending, state => {
			state.performance.status = RequestStatus.loading;
		});
		builder.addCase(getPerformance.fulfilled, (state, action) => {
			state.performance.status = RequestStatus.still;
			state.performance.data = action.payload;
		});
		builder.addCase(getPerformance.rejected, state => {
			state.performance.status = RequestStatus.failed;
		});

		builder.addCase(getBoardAndExec.pending, state => {
			state.boardAndExec.status = RequestStatus.loading;
		});
		builder.addCase(getBoardAndExec.fulfilled, (state, action) => {
			state.boardAndExec.status = RequestStatus.still;
			state.boardAndExec.data = action.payload;
		});
		builder.addCase(getBoardAndExec.rejected, state => {
			state.boardAndExec.status = RequestStatus.failed;
		});

		builder.addCase(getSelectedMetrics.pending, state => {
			state.selectedMetrics.status = RequestStatus.loading;
		});
		builder.addCase(getSelectedMetrics.fulfilled, (state, action) => {
			state.selectedMetrics.status = RequestStatus.still;
			state.selectedMetrics.data = action.payload;
		});
		builder.addCase(getSelectedMetrics.rejected, state => {
			state.selectedMetrics.status = RequestStatus.failed;
		});
	},
});

export const actionsDatabaseNew = {
	...slice.actions,
	getGeneral,
	getPerformance,
	getBoardAndExec,
	getSelectedMetrics,
};
