import { CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';
import s from './Preloader.module.scss';
import { FC } from 'react';

export const Preloader: FC<CircularProgressProps> = props => {
	const { ...restProps } = props;

	return (
		<CircularProgress
			className={s.container}
			{...restProps}
		/>
	);
};
