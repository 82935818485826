import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { ResponseStatus } from 'src/pages/MainPage/api/booleanSearch/types';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus } from 'src/shared/api/types';
import { wait } from 'src/shared/lib/async';
import { IPagination } from 'src/shared/types/pagination';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { parseCompaniesForTable } from '../_lib';
import { SelectedColumn } from '../customizeColumns/types';
import { BodyCompany } from '../searchResults/types';
import { CompaniesResponse, CompaniesResult } from '../types';

const NAME = 'excelExport';

const getExcelData = createAppAsyncThunk(`${NAME}/getExcelData`, async (args: { url: string; payload: BodyCompany }, thunkAPI) => {
	const { getState, rejectWithValue } = thunkAPI;
	const state = getState();
	const { url, payload } = args;

	const mode = state.mainSearch.data.searchMode;
	const booleanSearchResults = state.booleanSearch.searchResults;
	const activeColumns = state.customizeColumns.activeColumns;

	let body: any = null; // TODO: Fix types.

	if (mode === 'companiesBoolean' && booleanSearchResults) {
		body = {
			queryId: null,
			searchId: booleanSearchResults.result.searchId,
			associatedDataTakenFromCompany: payload.associatedDataTakenFromCompany,
			columnsWithPeriods: payload.columnsWithPeriods,
			pagination: payload.pagination,
		};
	} else {
		body = payload;
	}

	const arrayOfResults: CompaniesResult[] = [];

	let status: ResponseStatus = null;
	let queryId: number | null = null;

	do {
		const res: CompaniesResponse = await apiRequest.postRequest<CompaniesResponse>({
			url,
			payload: queryId !== null ? { queryId } : body,
			thunkAPI,
		});

		queryId = res?.queryId;
		status = res.status;

		if (res?.queryId && res.status === 'InProgress') {
			await wait(3000);
		} else if (res.result) {
			// Having result is same as status === 'Finished'.
			arrayOfResults.push(res.result);
		} else {
			return rejectWithValue(null);
		}
	} while (status === 'InProgress');

	const res = {
		companies: arrayOfResults.map(res => res.companies).flat(),
		pagination: {
			...payload.pagination,
		},
	};

	return {
		res,
		activeColumns,
	} as {
		res: CompaniesResult;
		activeColumns: SelectedColumn[];
	};
});

// * Reducer
export interface State {
	pagination: IPagination;
	excelResults: Array<{ [key: string]: string | number }> | null;
	lastDownloaded: number;
	status: RequestStatus;
}

export const initialState: State = {
	pagination: {
		pageIndex: 0,
		pageSize: 5000,
		pageCount: 0,
		count: 0,
	},
	excelResults: null,
	lastDownloaded: -1,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setLastDownloaded: (state, action: { payload: number }) => {
			state.lastDownloaded = action.payload;
		},
		setPagination: (state, action: { payload: IPagination }) => {
			state.pagination = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getExcelData.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getExcelData.fulfilled, (state, action) => {
			const { res, activeColumns } = action.payload;

			const parsedResults: Array<any> | null = parseCompaniesForTable(res.companies, activeColumns);

			state.pagination = state.pagination.pageCount !== res.pagination.pageCount ? res.pagination : state.pagination;
			state.excelResults = parsedResults;
			state.status = RequestStatus.still;
		});
		builder.addCase(getExcelData.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsExcelExport = {
	...slice.actions,
	getExcelData,
};
