import { ComponentPropsWithoutRef, FC } from 'react';
import s from './ButtonsWrapper.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'div'> {
	direction?: 'row' | 'column';
}

export const ButtonsWrapper: FC<Props> = props => {
	const {
		direction = 'row', //
		className,
		...restProps
	} = props;

	return (
		<div
			{...restProps}
			className={cn(s.button, s[`button_${direction}`], className)}
		/>
	);
};
