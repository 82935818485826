import { useEffect } from 'react';
import { Card } from 'src/shared/ui/_layout/Card';
import { Logo } from 'src/shared/ui/Logo';
import { SignInForm } from '../SignInForm/SignInForm';
import s from './SignInPage.module.scss';

export const SignInPage = () => {
	useEffect(() => {
		localStorage.clear();
	}, []);

	// * Render
	return (
		<>
			<div className={s.container}>
				<Card className={s.card}>
					<Logo className={s.logo} />

					<SignInForm />
				</Card>
			</div>
		</>
	);
};
