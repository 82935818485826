import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UrlAPI } from 'src/shared/api/types';

const NAME = UrlAPI.funds;

interface State {
	dimensions: number[];
	benchmarks: number[];
	indexes: number[];
	benchmarkType: number;
}

export const initialState: State = {
	dimensions: [],
	benchmarks: [],
	indexes: [],
	benchmarkType: 0,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setDimensions: (state, action: PayloadAction<number[]>) => {
			state.dimensions = action.payload;
		},
		setBenchmarks: (state, action: PayloadAction<number[]>) => {
			state.benchmarks = action.payload;
		},
		setIndexes: (state, action: PayloadAction<number[]>) => {
			state.indexes = action.payload;
		},
		setBenchmarkType: (state, action: PayloadAction<number>) => {
			state.benchmarkType = action.payload;
		},
		setConfig: (state, action: PayloadAction<{ dimensions: number[]; benchmarks: number[]; indexes: number[]; benchmarkType: number }>) => {
			const { dimensions, benchmarks, indexes, benchmarkType } = action.payload;

			state.dimensions = dimensions;
			state.benchmarks = benchmarks;
			state.indexes = indexes;
			state.benchmarkType = benchmarkType;
		},
	},
});

export const actionsFundsConfig = {
	...slice.actions,
};
