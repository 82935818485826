import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, FC } from 'react';

type Props = TextFieldProps & {
	value: string | number | undefined;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const StringInputOutlined: FC<Props> = props => {
	return (
		<TextField
			{...props}
			InputLabelProps={{ shrink: true }}
			size="small"
		/>
	);
};
