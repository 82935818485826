import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ButtonSquared } from 'src/shared/ui/_buttons/ButtonSquared';
import { OTPInput } from 'src/shared/ui/_inputs/OTPInput';
import s from './MFAForm.module.scss';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { actionsAuth } from '../../_BLL/slice';
import { FC } from 'react';

const OTP_LENGTH = 6;

interface Props {
	userName: string;
}

export const MFAForm: FC<Props> = props => {
	const {
		userName, //
	} = props;

	// * Selectors
	const status = useAppSelector(state => state.auth.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { loginMFA, setMFA } = actionsAuth;

	const schema = Yup.object().shape({
		password: Yup.string().min(OTP_LENGTH).max(OTP_LENGTH).required('Password is required'),
	});

	const formMethods = useForm({
		defaultValues: {
			password: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control } = formMethods;

	const onSubmit = (values: { password: string }) => {
		dispatch(
			loginMFA({
				username: userName,
				mfacode: values.password,
			}),
		)
			.unwrap()
			.then(() => {
				dispatch(setMFA(false));
			})
			.catch(error => console.log(error));
	};

	const backToLogin = () => {
		dispatch(setMFA(false));
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={s.container}
			>
				<Controller
					name="password"
					control={control}
					render={({ field, fieldState }) => (
						<OTPInput
							{...field}
							length={OTP_LENGTH}
							error={fieldState.error?.message}
						/>
					)}
				/>

				<div className={s.buttons}>
					<ButtonSquared
						variant="outlined"
						onClick={backToLogin}
						status={status}
					>
						Back
					</ButtonSquared>

					<ButtonSquared
						type="submit"
						status={status}
					>
						Submit
					</ButtonSquared>
				</div>
			</form>
		</FormProvider>
	);
};
