import { FC } from 'react';
import { NotificationItem } from 'src/app/_BLL/notifications/types';
import { NotificationSingle } from 'src/app/providers/NotificationsProvider/ui/notifications/NotificationSingle/NotificationSingle';
import s from './Notification.module.scss';

interface Props {
	notifications: NotificationItem[];
}

export const Notification: FC<Props> = props => {
	const {
		notifications, //
	} = props;

	return (
		<div className={s.container}>
			{notifications.map(note => (
				<NotificationSingle
					key={note.id}
					id={note.id}
					message={note.message}
					type={note.type}
				/>
			))}
		</div>
	);
};
