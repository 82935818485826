import { createContext, FC, ReactNode, useContext } from 'react';
import { ColumnsMeta, Dictionaries } from 'src/pages/SignInPage/_BLL/types';
import { UserData } from 'src/pages/SignInPage/_BLL/types';

interface Context {
	userData: UserData;
	dictionaries: Dictionaries;
	columnsMeta: ColumnsMeta;
}

interface Props extends Context {
	children: ReactNode;
}

const CoreDataContext = createContext<Context | null>(null);

const CoreDataContextProvider: FC<Props> = props => {
	const {
		userData, //
		dictionaries,
		columnsMeta,
		children,
	} = props;

	// * Render
	return (
		<CoreDataContext.Provider
			value={{
				userData,
				dictionaries,
				columnsMeta,
			}}
		>
			{children}
		</CoreDataContext.Provider>
	);
};

const useCoreDataContext = () => {
	const context = useContext(CoreDataContext);

	if (!context) {
		throw new Error('useCoreDataContext must be used within MetaContextProvider');
	}

	return context;
};

export { CoreDataContextProvider, useCoreDataContext };
