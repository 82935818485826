import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';

interface Props extends OutlinedInputProps {
	name: string;
	label: string;
}

export const PasswordField: FC<Props> = props => {
	const { label, name, ...inputProps } = props;

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
	};

	const { control } = useFormContext();

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<FormControl
					size="small"
					variant="outlined"
					error={!!fieldState.error?.message}
				>
					<InputLabel
						id={label}
						shrink={true}
					>
						{label}
					</InputLabel>

					<OutlinedInput
						{...field}
						{...inputProps}
						onChange={event => {
							if (inputProps.onChange) {
								inputProps.onChange(event); // Custom on change.
							} else {
								field.onChange(event);
							}
						}}
						label={label}
						type={showPassword ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
					{fieldState.error?.message && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
				</FormControl>
			)}
		/>
	);
};
