import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { IPagination } from 'src/shared/types/pagination';
import { Row, SearchExpression, SelectedOptions } from 'src/shared/types/portfolio_funds/portfolio_funds_table';
import { GetTableREQ } from './reqTypes';
import { GetTableRES } from './resTypes';

const NAME = UrlAPI.portfolioAnalyzer;

export const getTable = createAppAsyncThunk(`${NAME}/getTable`, async (arg: GetTableREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<GetTableRES>({
		url: `${NAME}/getTable`,
		payload,
		thunkAPI,
	});
});

export const getFullTable = createAppAsyncThunk(`${NAME}/getTableFromAllPages`, async (arg: GetTableREQ, thunkAPI) => {
	const { payload } = arg;

	const res = await apiRequest.postRequest<GetTableRES>({
		url: `${NAME}/getTable`,
		payload,
		thunkAPI,
	});

	// TODO: Load data in parallel...
	const fullData = [res.rows];

	if (res.pagination.pageCount > 1) {
		for (let i = 1; i < res.pagination.pageCount; i++) {
			const partRes = await apiRequest.postRequest<GetTableRES>({
				url: `${NAME}/getTable`,
				payload: {
					...payload,
					pagination: {
						...payload.pagination,
						pageIndex: res.pagination.pageIndex + i,
					},
				},
				thunkAPI,
			});

			fullData.push(partRes.rows);
		}
	}

	return fullData.flat();
});

interface State {
	table: {
		data: GetTableRES | null;
		status: RequestStatus;
	};
	fullTable: {
		data: Row[] | null;
		status: RequestStatus;
	};
	pagination: IPagination;
	dimensionID: number | null;
	selectedOptions: SelectedOptions;
	searchExpression: SearchExpression | null;
}

export const initialState: State = {
	table: {
		data: null,
		status: RequestStatus.still,
	},
	fullTable: {
		data: null,
		status: RequestStatus.still,
	},
	pagination: {
		pageSize: 10,
		pageIndex: 0,
		pageCount: 0,
		count: 0,
	},
	dimensionID: null,
	selectedOptions: {
		loadCountryPercentiles: false,
		loadIndustryPercentiles: false,
		loadCountryIndustryPercentiles: false,
		loadBenchmarkAndIndexScores: false,
		loadExposure: false,
	},
	searchExpression: null,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setRequestParams: (state, action: PayloadAction<{ dimensionID: number | null; selectedOptions: SelectedOptions; searchExpression: SearchExpression | null }>) => {
			const { dimensionID, selectedOptions, searchExpression } = action.payload;

			state.dimensionID = dimensionID;
			state.selectedOptions = selectedOptions;
			state.searchExpression = searchExpression;
		},
		setPagination: (state, action: PayloadAction<{ pagination: IPagination }>) => {
			state.pagination = action.payload.pagination;
		},
	},
	extraReducers: builder => {
		builder.addCase(getTable.pending, state => {
			state.table.status = RequestStatus.loading;
		});
		builder.addCase(getTable.fulfilled, (state, action) => {
			state.table.data = action.payload;
			state.pagination = action.payload.pagination;
			state.table.status = RequestStatus.still;
		});
		builder.addCase(getTable.rejected, state => {
			state.table.status = RequestStatus.failed;
		});

		builder.addCase(getFullTable.pending, state => {
			state.fullTable.status = RequestStatus.loading;
		});
		builder.addCase(getFullTable.fulfilled, (state, action) => {
			state.fullTable.data = action.payload;
			state.fullTable.status = RequestStatus.still;
		});
		builder.addCase(getFullTable.rejected, state => {
			state.fullTable.status = RequestStatus.failed;
		});
	},
});

export const actionsPortfolioTable = {
	...slice.actions,
	getTable,
	getFullTable,
};
