import Dexie, { Table } from 'dexie';
import { Dictionaries } from 'src/pages/SignInPage/_BLL/types';
import { ValueOf } from 'src/lib/type_checkers/valueOf';

export class MySubClassedDexie extends Dexie {
	dictionaries!: Table<{ name: keyof Dictionaries; value: ValueOf<Dictionaries> }>;

	constructor() {
		super('dictionaries');
		this.version(1).stores({
			dictionaries: 'name',
		});
	}
}

export const db = new MySubClassedDexie();
