import { SelectedColumn } from './types';

export const initialColumns: SelectedColumn[] = [
	{
		backendName: 'CompLocationStreet1',
		displayName: 'Location Street 1',
		reportingPeriodId: null,
		tooltip: 'Location Street 1',
		id: 1000014,
		order: 1,
		operators: ['Equals'],
		fieldType: null,
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: {
			LastUpdateTime: 151000014,
		},
		tableHeaderName: 'Location Street 1',
	},
	{
		backendName: 'CompLocationPostcode',
		displayName: 'Location Postcode',
		reportingPeriodId: null,
		tooltip: 'Location Postcode',
		id: 1000016,
		order: 3,
		operators: ['Equals'],
		fieldType: null,
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: {
			LastUpdateTime: 151000016,
		},
		tableHeaderName: 'Location Postcode',
	},
	{
		backendName: 'CompLocationCity',
		displayName: 'Location City',
		reportingPeriodId: null,
		tooltip: 'Location City',
		id: 1000017,
		order: 4,
		operators: ['Equals'],
		fieldType: null,
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: {
			LastUpdateTime: 151000017,
		},
		tableHeaderName: 'Location City',
	},
	{
		backendName: 'CompLocationCountry',
		displayName: 'Location Country',
		reportingPeriodId: null,
		tooltip: 'Location Country',
		id: 1000019,
		order: 6,
		operators: ['Equals'],
		fieldType: null,
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: {
			LastUpdateTime: 151000019,
		},
		tableHeaderName: 'Location Country',
	},
	{
		backendName: 'CompStatus',
		displayName: 'Status',
		reportingPeriodId: null,
		tooltip: 'Status',
		id: 1000204,
		order: 19,
		operators: ['Equals'],
		fieldType: null,
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: null, // TODO: Shouldn't be null
		tableHeaderName: 'Status',
	},
	{
		backendName: 'AdvancedTotalPlusScore',
		displayName: 'AdvancedM+ Total Score',
		reportingPeriodId: null,
		tooltip: 'AdvancedM+ Total Score',
		id: 10400001,
		order: 1,
		operators: ['Equals', 'NotEquals', 'Greater', 'GreaterOrEqual', 'Lesser', 'LesserOrEqual', 'IsEmpty', 'IsFilled'],
		fieldType: 'float',
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: null,
		tableHeaderName: 'AdvancedM+ Total Score',
	},
	{
		backendName: 'ACompanyLevelPlusScore',
		displayName: 'AdvancedM+ Company Level Score',
		reportingPeriodId: null,
		tooltip: 'AdvancedM+ Company Level Score',
		id: 10401001,
		order: 3,
		operators: ['Equals', 'NotEquals', 'Greater', 'GreaterOrEqual', 'Lesser', 'LesserOrEqual', 'IsEmpty', 'IsFilled'],
		fieldType: 'float',
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: null,
		tableHeaderName: 'AdvancedM+ Company Level Score',
	},
	{
		backendName: 'BoardLevelPlusScore',
		displayName: 'BoardM+ Total Score',
		reportingPeriodId: null,
		tooltip: 'BoardM+ Total Score',
		id: 10406001,
		order: 6,
		operators: ['Equals', 'NotEquals', 'Greater', 'GreaterOrEqual', 'Lesser', 'LesserOrEqual', 'IsEmpty', 'IsFilled'],
		fieldType: 'float',
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: null,
		tableHeaderName: 'BoardM+ Total Score',
	},
	{
		backendName: 'ExecLevelPlusScore',
		displayName: 'ExecutiveM+ Total Score',
		reportingPeriodId: null,
		tooltip: 'ExecutiveM+ Total Score',
		id: 10405001,
		order: 5,
		operators: ['Equals', 'NotEquals', 'Greater', 'GreaterOrEqual', 'Lesser', 'LesserOrEqual', 'IsEmpty', 'IsFilled'],
		fieldType: 'float',
		customLogic: null,
		dictionary: null,
		rangeLow: null,
		rangeHigh: null,
		isAssociated: false,
		associatedDataTakenFromCompany: false,
		relatedDatapoints: null,
		tableHeaderName: 'ExecutiveM+ Total Score',
	},
];
