import { localStorageName } from 'src/app/redux/localStorage';
import { RootState } from 'src/app/redux/rootReducer';
import { handleResponse } from 'src/shared/api/shared';
import { State } from 'src/pages/SignInPage/_BLL/slice';

// Prod - https://denominator-api-gold-prod.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com
// Dev - https://denominator-api-gold-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com
const host = process.env.REACT_APP_HOST || 'Error... in building REACT_APP_HOST';

export class ResponseError extends Error {
	severity: ErrorSeverity;
	incidentId?: string;

	constructor(message: any, severity: ErrorSeverity, incidentId?: string, ...params: any) {
		super(...params);
		this.severity = severity;
		this.message = message;
		this.incidentId = incidentId;
	}
}

const getAuth = (): State | null => {
	const storedState = localStorage.getItem(localStorageName);
	const parsedState = storedState ? (JSON.parse(storedState) as RootState) : null;
	return parsedState ? parsedState.auth : null;
};

const getHeaders = (token?: string) => {
	const authState = getAuth();

	const apiToken = token || (authState && authState.userData ? authState.userData.token : '');

	return {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		'x-api-token': apiToken,
	};
};

const getHeadersFormData = (token?: string) => {
	const authState = getAuth();

	const apiToken = token || (authState && authState.userData ? authState.userData.token : '');

	return {
		Accept: '*/*',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		'x-api-token': apiToken,
	};
};

// * RTK api
export const getRequestUrl = (url: string, params?: string) => {
	return `${host}/${url}${params ? '?' + params : ''}`;
};

export const generateParams = (params?: object) => {
	return params
		? Object.entries(params)
				.map(([key, value]) => `${key}=${value}`)
				.join('&')
		: '';
};

// * RTK pure requests
interface FetchGET {
	url: string;
	params?: object;
	signal?: AbortSignal;
	token?: string;
}

const GET = async (args: FetchGET) => {
	const { url, params, signal, token } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'GET',
		headers: getHeaders(token),
		referrerPolicy: 'no-referrer',
		signal,
	});
};

interface FetchPOST {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

const POST = async (args: FetchPOST) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchPOST_FormData {
	url: string;
	params?: object;
	payload?: BodyInit | null | undefined;
	signal?: AbortSignal;
}

const POST_FormData = async (args: FetchPOST_FormData) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'POST',
		mode: 'cors',
		headers: getHeadersFormData(),
		referrerPolicy: 'no-referrer',
		body: payload,
		signal,
	});
};

interface FetchPUT {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

export const PUT = async (args: FetchPUT) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'PUT',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchDEL {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

const DEL = async (args: FetchDEL) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'DELETE',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

// * RTK request handlers
const getRequest = async <RES>(args: FetchGET & { thunkAPI: any }) => {
	const { thunkAPI, ...getArgs } = args;

	const response = await GET(getArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const postRequest = async <RES>(args: FetchPOST & { thunkAPI: any }) => {
	const { thunkAPI, ...postArgs } = args;

	const response = await POST(postArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const postFormDataRequest = async <RES>(args: FetchPOST_FormData & { thunkAPI: any }) => {
	const { thunkAPI, ...postArgs } = args;

	const response = await POST_FormData(postArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const putRequest = async <RES>(args: FetchPUT & { thunkAPI: any }) => {
	const { thunkAPI, ...putArgs } = args;

	const response = await PUT(putArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const delRequest = async <RES>(args: FetchDEL & { thunkAPI: any }) => {
	const { thunkAPI, ...delArgs } = args;

	const response = await DEL(delArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

// * Export
export const apiRequest = {
	getRequest,
	postRequest,
	postFormDataRequest,
	putRequest,
	delRequest,
};

// * Types
export type ErrorSeverity = 'Information' | 'Warning' | 'Error' | 'AccessDenied' | 'Critical';
