import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { themes } from 'src/shared/styles/common/themes/themes';

interface Props {
	children: ReactNode;
}

export const ThemeDecorator: FC<Props> = props => {
	const { children } = props;

	// * Selectors
	const activeTheme = useAppSelector(state => state.auth.activeTheme);

	// * Render
	return (
		<MUIThemeProvider theme={themes[activeTheme]}>
			<StyledEngineProvider injectFirst>
				<div data-theme={activeTheme}>{children}</div>
			</StyledEngineProvider>
		</MUIThemeProvider>
	);
};
