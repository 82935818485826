import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { SavedQuery } from './types/type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/shared/api/api';
import { EditQueryREQ } from './types/reqTypes';
import { replaceArrayItemByID } from 'src/shared/lib/array';

const NAME = 'saved-queries';

const getSavedQueries = createAsyncThunk(`${NAME}/getSavedQueries`, async (_: void, thunkAPI) => {
	return await apiRequest.getRequest<SavedQuery[]>({
		url: UrlAPI.savedQueries,
		thunkAPI,
	});
});

const deleteSavedQuery = createAsyncThunk(`${NAME}/deleteSavedQuery`, async (params: { id: number }, thunkAPI) => {
	return await apiRequest.delRequest<SavedQuery[]>({
		url: UrlAPI.savedQueries,
		params,
		thunkAPI,
	});
});

const createCommonSavedQuery = createAsyncThunk(`${NAME}/createCommonSavedQuery`, async (payload: EditQueryREQ, thunkAPI) => {
	return await apiRequest.postRequest<SavedQuery[]>({
		url: UrlAPI.commonSavedQueries,
		payload,
		thunkAPI,
	});
});

const editCommonSavedQuery = createAsyncThunk(`${NAME}/editCommonSavedQuery`, async (args: { params: { id: number }; payload: EditQueryREQ }, thunkAPI) => {
	const { params, payload } = args;

	return await apiRequest.putRequest<SavedQuery>({
		url: UrlAPI.commonSavedQueries,
		params,
		payload,
		thunkAPI,
	});
});

const createUserSavedQuery = createAsyncThunk(`${NAME}/createUserSavedQuery`, async (payload: EditQueryREQ, thunkAPI) => {
	return await apiRequest.postRequest<SavedQuery[]>({
		url: UrlAPI.userSavedQueries,
		payload,
		thunkAPI,
	});
});

const editUserSavedQuery = createAsyncThunk(`${NAME}/editUserSavedQuery`, async (args: { params: { id: number }; payload: EditQueryREQ }, thunkAPI) => {
	const { params, payload } = args;

	return await apiRequest.putRequest<SavedQuery>({
		url: UrlAPI.userSavedQueries,
		params,
		payload,
		thunkAPI,
	});
});

// * Reducer
interface State {
	savedQueries: SavedQuery[];
	status: RequestStatus;
}

export const initialState: State = {
	savedQueries: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getSavedQueries.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getSavedQueries.fulfilled, (state, action) => {
			state.savedQueries = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getSavedQueries.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(deleteSavedQuery.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(deleteSavedQuery.fulfilled, (state, action) => {
			state.savedQueries = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(deleteSavedQuery.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(createCommonSavedQuery.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createCommonSavedQuery.fulfilled, (state, action) => {
			state.savedQueries = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(createCommonSavedQuery.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editCommonSavedQuery.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editCommonSavedQuery.fulfilled, (state, action) => {
			state.savedQueries = replaceArrayItemByID(state.savedQueries, action.payload);
			state.status = RequestStatus.still;
		});
		builder.addCase(editCommonSavedQuery.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(createUserSavedQuery.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createUserSavedQuery.fulfilled, (state, action) => {
			state.savedQueries = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(createUserSavedQuery.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editUserSavedQuery.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editUserSavedQuery.fulfilled, (state, action) => {
			state.savedQueries = replaceArrayItemByID(state.savedQueries, action.payload);
			state.status = RequestStatus.still;
		});
		builder.addCase(editUserSavedQuery.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsSavedQueries = {
	getSavedQueries,
	deleteSavedQuery,
	createCommonSavedQuery,
	editCommonSavedQuery,
	createUserSavedQuery,
	editUserSavedQuery,
};
