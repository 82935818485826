import { ReactNode } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { DataPoint, Dictionaries } from 'src/pages/SignInPage/_BLL/types';
import { CoreDataContextProvider } from 'src/app/contexts/useCoreDataContext';
import { UserData } from 'src/pages/SignInPage/_BLL/types';

interface CoreData {
	dictionaries: Dictionaries;
	dataPointTree: DataPoint[];
	dataPoints: Record<string, DataPoint>;
	// peerTotalScoreDataPointTree: DataPoint[];
}

export const renderRoute = (path: string, PageComponent: ReactNode, userData: UserData | null, coreData: CoreData | null) => {
	return (
		<Route
			path={path}
			element={
				userData ? (
					<>
						{coreData ? (
							<CoreDataContextProvider
								userData={userData}
								dictionaries={coreData.dictionaries}
								columnsMeta={{
									dataPoints: coreData.dataPoints,
									dataPointTree: coreData.dataPointTree,
								}}
							>
								{PageComponent}
							</CoreDataContextProvider>
						) : (
							<>
								<span>{JSON.stringify(coreData)}</span>
							</>
						)}
					</>
				) : (
					<Navigate
						to="/"
						replace
					/>
				)
			}
		/>
	);
};
