import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/shared/api/api';
import { createDebouncedAsyncThunk } from 'src/shared/api/createDebouncedAsyncThunk';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { IPagination } from 'src/shared/types/pagination';
import { actionsNotifications } from '../../../../app/_BLL/notifications/slice';
import { CompaniesLookupRES, ExtraSearchOptions, SearchMode, SearchResponse, SearchResponseCompany } from './types';

const NAME = 'main-search';

// * API requests
interface LookupREQ_PARAMS {
	search_prefix: string;
	page_count: number;
	page_index: number;
	page_size: number;
}

interface IndustriesLookupRES {
	industries: SearchResponse[];
	pagination: IPagination;
}

const industriesLookup = createDebouncedAsyncThunk(
	`${NAME}/industriesLookup`,
	async (params: LookupREQ_PARAMS, thunkAPI) => {
		const { signal } = thunkAPI;

		if (params.search_prefix !== '') {
			return await apiRequest.getRequest<IndustriesLookupRES>({
				url: UrlAPI.industriesLookup,
				params,
				thunkAPI,
				signal,
			});
		} else {
			return {
				industries: [],
				pagination: {
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
					count: 0,
				},
			};
		}
	},
	500,
);

interface CountriesLookupRES {
	geo: SearchResponse[];
	pagination: IPagination;
}

const countriesLookup = createDebouncedAsyncThunk(
	`${NAME}/countriesLookup`,
	async (params: LookupREQ_PARAMS, thunkAPI) => {
		const { signal } = thunkAPI;

		if (params.search_prefix !== '') {
			return await apiRequest.getRequest<CountriesLookupRES>({
				url: UrlAPI.countriesLookup,
				params,
				thunkAPI,
				signal,
			});
		} else {
			return {
				geo: [],
				pagination: {
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
					count: 0,
				},
			};
		}
	},
	500,
);

interface CompaniesLookupREQ_BODY {
	ids?: number[];
	leis?: string[];
	isins?: string[];
	cusip?: string[];
	sedol?: string[];
	portfolioIDs?: number[];
	searchPrefix?: string;
	pagination?: IPagination;
}

const companiesLookup = createDebouncedAsyncThunk(
	`${NAME}/companiesLookup`,
	async (payload: CompaniesLookupREQ_BODY, thunkAPI) => {
		const { signal, dispatch } = thunkAPI;

		if (payload.searchPrefix !== '') {
			const res = await apiRequest.postRequest<CompaniesLookupRES>({
				url: UrlAPI.companiesLookup,
				payload,
				thunkAPI,
				signal,
			});

			payload.ids && dispatch(actionsMainSearch.storeExtraSearchOptions({ ids: payload.ids }));
			payload.leis && dispatch(actionsMainSearch.storeExtraSearchOptions({ leis: payload.leis }));
			payload.isins && dispatch(actionsMainSearch.storeExtraSearchOptions({ isins: payload.isins }));
			payload.cusip && dispatch(actionsMainSearch.storeExtraSearchOptions({ cusip: payload.cusip }));
			payload.sedol && dispatch(actionsMainSearch.storeExtraSearchOptions({ sedol: payload.sedol }));
			payload.portfolioIDs && dispatch(actionsMainSearch.storeExtraSearchOptions({ portfolioIDs: payload.portfolioIDs }));

			if (res.companies.length === 0) {
				dispatch(
					actionsNotifications.addNotification({
						type: 'info',
						message: 'Nothing found',
					}),
				);
			}

			return res;
		} else {
			return {
				companies: [],
				pagination: {
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
					count: 0,
				},
			};
		}
	},
	500,
);

// * Reducer
interface State {
	data: {
		companies: SearchResponseCompany[];
		countries: SearchResponse[];
		industries: SearchResponse[];
		pagination: IPagination;
		search: string;
		searchMode: SearchMode;
		optionsIndustry: number[];
		optionsCountry: number[];
		selectedRegionId: number | null;
	};
	extraSearchOptions: ExtraSearchOptions;
	status: RequestStatus;
}

export const initialState: State = {
	data: {
		search: '', // Search current value
		searchMode: 'lookup',
		companies: [], // Search result for companies
		industries: [], // Search result for industries
		countries: [], // Search result for countries
		optionsIndustry: [], // List of industries user chose in the from search criteria menu
		optionsCountry: [], // List of regions/subregions/countries user chose in the from search criteria menu
		selectedRegionId: null,
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			pageCount: 0,
			count: 0,
		},
	},
	extraSearchOptions: {
		ids: [],
		leis: [],
		isins: [],
		cusip: [],
		sedol: [],
	},
	status: RequestStatus.still, // Request status
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setSearchString: (state, action: { payload: { searchString: string } }) => {
			state.data.search = action.payload.searchString;
			state.data.companies = [];
			state.data.industries = [];
			state.data.countries = [];
		},
		storeExtraSearchOptions: (state, action: { payload: Pick<CompaniesLookupREQ_BODY, 'ids' | 'leis' | 'isins' | 'cusip' | 'sedol' | 'portfolioIDs'> }) => {
			const { payload } = action;

			// @ts-ignore
			Object.entries(payload).forEach(([key, value]) => (state.extraSearchOptions[key] = value));
		},
		storeIndustryOptions: (state, action: { payload: { id: number | null } }) => {
			const { id } = action.payload;

			if (id === null) {
				state.data.optionsIndustry = [];
			} else {
				const { optionsIndustry } = state.data;

				state.data.optionsIndustry = optionsIndustry.includes(id) ? optionsIndustry.filter(opt => opt !== id) : [...optionsIndustry, id];
			}
		},
		storeCountryOptions: (state, action: { payload: { id: number; checked: boolean; regions: any; selectedRegionId: number } }) => {
			const { id, checked, regions, selectedRegionId } = action.payload;

			// TODO: Filtering logic should not be in reducer!!! Move to component logic.
			const optionsCountry = state.data.optionsCountry;

			let selectedOptions: Array<any> = [];

			const getIdsOfRegion = (regions: any, id: number) => {
				const selectedRegion = regions.find((region: any) => region.id === id);
				const subregions = selectedRegion.children.map((subregion: any) => subregion);
				const allCountriesOfTheRegion = subregions.map((country: any) => country.children).flat();

				const regionId = selectedRegion.id;
				const subregionsIds = subregions.map((subregion: any) => subregion.id);
				const allCountriesOfRegionIds = allCountriesOfTheRegion.map((country: any) => country.id);

				return { regionId, subregionsIds, allCountriesOfRegionIds };
			};

			const getIdsOfSubregion = (regions: any, id: number, selectedRegionId: number) => {
				const region = regions.filter((region: any) => region.id === selectedRegionId)[0];
				const subregions = region.children;
				const selectedSubregion = subregions.find((subregion: any) => subregion.id === id);
				const allCountriesOfSubregion = selectedSubregion.children;

				const subregionId = selectedSubregion.id;
				const allCountriesOfTheSubregionIds = allCountriesOfSubregion.map((country: any) => country.id);

				return { subregionId, allCountriesOfTheSubregionIds };
			};

			if (checked) {
				// Select country
				if (id.toString().length < 4) {
					selectedOptions = [...optionsCountry, id];
				}

				// Select subregion
				if (id.toString().length === 4) {
					const { subregionId, allCountriesOfTheSubregionIds } = getIdsOfSubregion(regions, id, selectedRegionId);
					selectedOptions = [...optionsCountry, subregionId, ...allCountriesOfTheSubregionIds];
				}

				// Select region
				if (id.toString().length > 4) {
					const { regionId, subregionsIds, allCountriesOfRegionIds } = getIdsOfRegion(regions, id);
					selectedOptions = [...optionsCountry, regionId, ...subregionsIds, ...allCountriesOfRegionIds];
				}
			} else {
				// Unselect country
				if (id.toString().length < 4) {
					selectedOptions = optionsCountry.filter(option => option !== id);
				}

				// Unselect subregion
				if (id.toString().length === 4) {
					const { subregionId, allCountriesOfTheSubregionIds } = getIdsOfSubregion(regions, id, selectedRegionId);
					const unselectedOptions = [subregionId, ...allCountriesOfTheSubregionIds];
					selectedOptions = optionsCountry.filter(option => !unselectedOptions.includes(option));
				}

				// Unselect region
				if (id.toString().length > 4) {
					const { regionId, subregionsIds, allCountriesOfRegionIds } = getIdsOfRegion(regions, id);
					const unselectedOptions = [regionId, ...subregionsIds, ...allCountriesOfRegionIds];
					selectedOptions = optionsCountry.filter(option => !unselectedOptions.includes(option));
				}
			}

			state.data.optionsCountry = selectedOptions.filter((item, index) => selectedOptions.indexOf(item) === index);
		},
		storeSelectedRegionId: (state, action: { payload: { id: number | null } }) => {
			state.data.selectedRegionId = action.payload.id;
		},
		paginationIncrement: state => {
			state.data.pagination.pageIndex = state.data.pagination.pageIndex + 1;
		},
		changeSearchMode: (state, action: { payload: { mode: SearchMode } }) => {
			state.data.searchMode = action.payload.mode;
		},
		clearResults: state => {
			state.data = {
				...state.data,
				companies: [],
				countries: [],
				industries: [],
				pagination: {
					...state.data.pagination,
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
				},
			};
		},
		clearLookup: state => {
			// This reducer activates when the user clicks away from search bar.
			// Options and status should not be reset...
			// The reducer activate only when there are search results.
			state.extraSearchOptions = initialState.extraSearchOptions;
			state.data = {
				...state.data,
				search: '',
				companies: [],
				countries: [],
				industries: [],
				pagination: {
					...state.data.pagination,
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
					count: 0,
				},
			};
		},
		clearCountyOptions: state => {
			state.data.optionsCountry = [];
		},
		clearAll: state => {
			state.data = initialState.data;
			state.extraSearchOptions = initialState.extraSearchOptions;
			state.status = initialState.status;
		},
	},
	extraReducers: builder => {
		builder.addCase(industriesLookup.pending, (state, action) => {
			const searchPrefix = action.meta.arg.search_prefix;
			state.status = searchPrefix === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(industriesLookup.fulfilled, (state, action) => {
			state.data.industries = [...state.data.industries, ...action.payload.industries];
			state.data.pagination = action.payload.pagination;
			state.status = RequestStatus.still;
		});
		builder.addCase(industriesLookup.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(countriesLookup.pending, (state, action) => {
			const searchPrefix = action.meta.arg.search_prefix;
			state.status = searchPrefix === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(countriesLookup.fulfilled, (state, action) => {
			state.data.countries = [...state.data.countries, ...action.payload.geo];
			state.data.pagination = action.payload.pagination;
			state.status = RequestStatus.still;
		});
		builder.addCase(countriesLookup.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(companiesLookup.pending, (state, action) => {
			const searchPrefix = action.meta.arg.searchPrefix;
			state.status = searchPrefix === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(companiesLookup.fulfilled, (state, action) => {
			state.data.companies = [...state.data.companies, ...action.payload.companies];
			state.data.pagination = action.payload.pagination;
			state.status = RequestStatus.still;
		});
		builder.addCase(companiesLookup.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsMainSearch = {
	...slice.actions,
	industriesLookup,
	countriesLookup,
	companiesLookup,
};
