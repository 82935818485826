import { ComponentPropsWithRef, FC } from 'react';
import s from './Card.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithRef<'div'> {}

export const Card: FC<Props> = props => {
	const {
		className, //
		...divProps
	} = props;

	return (
		<div
			{...divProps}
			className={cn(s.card, className)}
		/>
	);
};
