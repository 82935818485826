import { ErrorCritical, NotificationItem } from 'src/app/_BLL/notifications/types';
import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

// * Reducer

interface State {
	error: ErrorCritical | null;
	notifications: NotificationItem[];
}

export const initialState: State = {
	error: null,
	notifications: [],
};

export const slice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		clearError: state => {
			state.error = null;
		},
		setError: (state, action: { payload: ErrorCritical }) => {
			state.error = action.payload;
		},
		addNotification: (state, action: { payload: Omit<NotificationItem, 'id'> }) => {
			const { type, message } = action.payload;
			state.notifications = [...state.notifications, { id: v4(), type, message }];
		},
		deleteNotification: (state, action: { payload: Pick<NotificationItem, 'id'> }) => {
			const { id } = action.payload;
			state.notifications = state.notifications.filter(note => note.id !== id);
		},
	},
});

export const actionsNotifications = slice.actions;
