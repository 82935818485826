import { Modal } from 'src/shared/ui/Modal';
import { CriticalErrorModalContent } from 'src/app/providers/NotificationsProvider/ui/CriticalErrorModalContent/CriticalErrorModalContent';
import { useActions, useAppSelector } from 'src/app/redux/createAction';
import { actionsNotifications } from 'src/app/_BLL/notifications/slice';
import { Notification } from 'src/app/providers/NotificationsProvider/ui/notifications/Notification/Notification';
import { FC } from 'react';

export const NotificationsProvider: FC = () => {
	// * Selectors
	const { error, notifications } = useAppSelector(state => state.notifications);

	// * Actions
	const { clearError } = useActions(actionsNotifications);

	// * Render
	return (
		<>
			<Modal
				toggleModal={clearError}
				Content={<CriticalErrorModalContent error={error} />}
				show={!!error}
				width={40}
				closeOnBackdrop={false}
			/>
			<Notification notifications={notifications} />
		</>
	);
};
