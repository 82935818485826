// Takes the last unique object in the array...
export const removeDuplicates = <T>(array: Array<T>, filterValue: keyof T) => {
	const newArray = [];
	const lookupObject: any = {};

	for (const i in array) {
		lookupObject[array[i][filterValue]] = array[i];
	}

	for (const i in lookupObject) {
		newArray.push(lookupObject[i]);
	}
	return newArray;
};
