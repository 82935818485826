import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { BooleanRequest, SearchResult, SelectedOption } from './types';

const NAME = 'boolean-search';

const getBooleanSearchResult = createAppAsyncThunk(`${NAME}/getBooleanSearchResult`, async (payload: BooleanRequest, thunkAPI) => {
	const { signal } = thunkAPI;

	return await apiRequest.postRequest<SearchResult>({
		url: `${UrlAPI.companies}/booleanSearch`,
		payload,
		thunkAPI,
		signal,
	});
});

const cancelBooleanSearch = createAppAsyncThunk(`${NAME}/cancelBooleanSearch`, async (payload: { queryId: BooleanRequest['queryId'] }, thunkAPI) => {
	return await apiRequest.postRequest<SearchResult>({
		url: `${UrlAPI.companies}/cancel`,
		params: {
			id: payload.queryId,
		},
		thunkAPI,
	});
});

// * Reducer
interface State {
	activeCriteria: number | null;
	selectedOptions: SelectedOption[];
	searchResults: SearchResult | null;
	lastSearchRequest: BooleanRequest | null;
	status: RequestStatus;
}

export const initialState: State = {
	activeCriteria: null,
	selectedOptions: [],
	searchResults: null,
	lastSearchRequest: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setCriteria: (state, action: { payload: { optionId: number } }) => {
			state.activeCriteria = action.payload.optionId;
		},
		addOption: (state, action: { payload: { newOption: SelectedOption } }) => {
			const { newOption } = action.payload;

			state.selectedOptions = [...state.selectedOptions, newOption];
		},
		deleteOption: (state, action: { payload: { index: number } }) => {
			state.selectedOptions = state.selectedOptions.filter((option, index) => index !== action.payload.index);
		},
		setOptions: (state, action: PayloadAction<SelectedOption[]>) => {
			state.selectedOptions = action.payload;
		},
		clearOptions: state => {
			state.selectedOptions = [];
			state.searchResults = null;
		},
		setLastSearchRequest: (state, action: PayloadAction<BooleanRequest>) => {
			state.lastSearchRequest = action.payload;
		},
		setSearchResults: (state, action: PayloadAction<SearchResult>) => {
			state.searchResults = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getBooleanSearchResult.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getBooleanSearchResult.fulfilled, (state, action) => {
			if (action.payload?.status === 'Finished' || action.payload?.status === null) {
				state.searchResults = action.payload;
				state.status = RequestStatus.still;
			}
		});
		builder.addCase(getBooleanSearchResult.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(cancelBooleanSearch.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(cancelBooleanSearch.fulfilled, state => {
			state.status = RequestStatus.failed; // ! Exception. RequestStatus.failed triggers 'Load Again'.
		});
		builder.addCase(cancelBooleanSearch.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsBooleanSearch = {
	...slice.actions,
	getBooleanSearchResult,
	cancelBooleanSearch,
};
