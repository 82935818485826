import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';
import { rootReducer } from './rootReducer';

import { initialState as database } from '../../pages/DatabasePage/api/database/slice';
import { initialState as databaseNew } from '../../pages/DatabaseNewPage/api/database/slice';
import { initialState as chatGPT } from '../../pages/MainPage/api/chatGPT/slice';
import { initialState as mainSearchExcel } from '../../pages/MainSearchResultsPage/api/excelExport/slice';
import { initialState as notifications } from '../_BLL/notifications/slice';
import { initialState as savedQueries } from 'src/pages/MainPage/api/savedQueries/slice';
// Portfolio Analyzer
import { initialState as portfolioAnalyzer } from 'src/pages/portfolio_analyzer_pages/api/portfolio_analyzer/slice';
import { initialState as portfolioTable } from 'src/pages/portfolio_analyzer_pages/api/portfolio_table/slice';
import { initialState as portfolioConfig } from 'src/pages/portfolio_analyzer_pages/api/portfolio_config/slice';
// Funds
import { initialState as funds } from 'src/pages/funds_pages/api/funds/slice';
import { initialState as fundsTable } from 'src/pages/funds_pages/api/table/slice';
import { initialState as fundsConfig } from 'src/pages/funds_pages/api/config/slice';

const preloadedState = loadState();

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: false,
		}),
	devTools: { trace: false },
	preloadedState,
});

store.subscribe(() => {
	saveState({
		notifications,
		auth: store.getState().auth,
		mainSearch: store.getState().mainSearch,
		booleanSearch: store.getState().booleanSearch,
		savedQueries,
		mainSearchResults: store.getState().mainSearchResults,
		mainSearchExcel,
		database,
		databaseNew,
		customizeColumns: store.getState().customizeColumns,
		savedColumns: store.getState().savedColumns,
		chatGPT,
		portfolioAnalyzer,
		portfolioTable,
		portfolioConfig,
		funds,
		fundsTable,
		fundsConfig,
	});
});

export type AppDispatch = typeof store.dispatch;
